import "@fontsource/bebas-neue"
import "@fontsource/karla"

import React, { useState } from "react"
import { content, content_inner, featured_image } from "../assets/global"

import Image from "gatsby-image"
import Layout from "../components/layout"
import Modal from "react-modal"
import PageHeader from "../components/page_header"
import QuickMenu from "../components/quick_menu"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"
import { whr_blue } from "../assets/colours"

const ServicesTemplate = ({ data: { service } }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  function openModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  const featuredImage = {
    fluid: service.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: service.featuredImage?.node?.alt || ``,
  }

  const quick_menu_links =
    service.services_fields.services != null
      ? service.services_fields.services.map(service_field => {
          return {
            title: service_field?.title,
            id: service_field?.title?.replace(/\s+/g, "-")?.toLowerCase(),
          }
        })
      : null

  const service_sections = () => {
    if (service.services_fields.services != null) {
      return service.services_fields.services.map((service_field, index) => {
        return (
          <section
            key={index}
            id={service_field?.title?.replace(/\s+/g, "-")?.toLowerCase()}
          >
            <h1>{service_field.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(service_field.servicesContent, {
                  allowedTags: [
                    "iframe",
                    "a",
                    "h1",
                    "h2",
                    "h3",
                    "h4",
                    "h5",
                    "h6",
                    "p",
                    "strong",
                    "img",
                    "ul",
                    "li",
                    "div",
                  ],
                  allowedAttributes: {
                    a: ["style", "href", "target", "rel"],
                    img: ["src", "alt", "class"],
                    ul: ["style"],
                    iframe: ["src", "style"],
                    div: ["style", "class"],
                  },
                }),
              }}
            />
            {service_field.title === "Iggy Interoception" && (
              <>
                <p>
                  <strong>I want to purchase Iggy Interoception:</strong>
                </p>
                <p>
                  If you're interesting in getting a copy, please click the
                  button below and submit your details through our Microsoft
                  Form.
                </p>
                <p>
                  Our admin team will be in touch with you to organise your
                  copy!
                </p>
                <button
                  className="bg-whr-orange px-6 py-2 uppercase text-white"
                  onClick={openModal}
                >
                  Get my copy!
                </button>
                <Modal
                  isOpen={modalOpen}
                  onRequestClose={closeModal}
                  className="bg-white shadow-2xl"
                  style={{
                    content: {
                      marginTop: "3rem",
                      marginBottom: "3rem",
                      height: "calc(100% - 1.5rem)",
                      maxWidth: "100%",
                      padding: "1.5rem",
                    },
                  }}
                >
                  <iframe
                    width="640px"
                    height="480px"
                    style={{ height: "100%", maxWidth: "100%" }}
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=UjSXKtqFzUuu6024zzRm73r5PHwfVqBCtUeeL0gfFcFUMUdGQjZKTTJQWkFEODNQWEFKRFNWNDRBMi4u&embed=true"
                    allowfullscreen
                    webkitallowfullscreen
                    mozallowfullscreen
                    msallowfullscreen
                  />
                </Modal>
              </>
            )}
          </section>
        )
      })
    } else {
      return <h1>Coming soon.</h1>
    }
  }

  return (
    <Layout
      title={service.seo.title}
      menuOpen={menuOpen}
      toggleMenu={toggleMenu}
      fullWidthMenu={false}
    >
      <Seo description={service.seo.metaDesc} title={service.seo.title} />

      {/* if we have a featured image for this post let's display it */}
      {featuredImage?.fluid && (
        <FeaturedImage>
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
            style={{ height: "100%" }}
          />
        </FeaturedImage>
      )}

      <Content>
        <PageHeader title={service.title}></PageHeader>
        {service.services_fields.services != null && (
          <QuickMenu
            jump_text="Jump To"
            menu_items={quick_menu_links}
          ></QuickMenu>
        )}

        <ContentInner>
          {service.services_fields.services && service_sections()}
          {service.services_fields.services == null && <h1>Coming soon.</h1>}
        </ContentInner>
      </Content>
    </Layout>
  )
}

export default ServicesTemplate

const FeaturedImage = styled.div`
  ${featured_image};
`

const Content = styled.div`
  ${content};

  button {
    font-family: "Bebas Neue";
    letter-spacing: 1px;
    font-size: 1.5rem;

    &:hover {
      background: ${whr_blue};
      transition: 0.3s;
    }
  }
`

const ContentInner = styled.div`
  ${content_inner};

  .grid-3,
  .grid-2 {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1.5rem;

    & > *,
    .grid- {
      box-shadow: rgb(207, 207, 207) 5px 5px 5px 0px;
    }

    & > *:hover {
      opacity: 0.7;
      transition: opacity 0.4s;
    }
  }

  @media (min-width: 48rem) {
    .grid-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .grid-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
`

export const pageQuery = graphql`
  query ServiceById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    service: wpServices(id: { eq: $id }) {
      id
      title
      seo {
        metaDesc
        title
      }
      services_fields {
        services {
          servicesContent
          title
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
